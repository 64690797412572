<template>
  <div
    class="form-group d-flex"
    :class="props.className?.wrapper"
  >
    <input
      class="cursor-pointer my-1 flex-shrink-0"
      type="checkbox"
      :id="id"
      :true-value="!props.inverseValue"
      :false-value="!!props.inverseValue"
      v-model="inputValue"
      :disabled="props.disabled"
      :value="props.returnValue"
      :class="[
            switcher ? 'switcher' : 'form-check-input',
            {
              'is-invalid': props.error,
              'me-2': props.label,
            }]"
    >
    <label
      v-if="props.label"
      class="form-check-label cursor-pointer small"
      :class="props.className?.label"
      :for="id"
      v-html="props.label"
    />
  </div>
</template>

<script setup>
import {computed} from 'vue';
import stringHelper from '@/common/helpers/stringHelper';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  className: {
    type: Object,
  },
  value: {
    type: [Boolean, Array],
    required: true,
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  returnValue: {
    type: [String, Number],
  },
  inverseValue: {
    type: Boolean,
    default: false,
  },
  switcher: Boolean,
});

const id = stringHelper.getRandomUUID();

const emit = defineEmits(['update:value']);

const inputValue = computed({
    get() {
      return props.value;
    },
    set(value) {
      emit('update:value', value);
    },
  },
);

</script>

<style scoped>
.switcher {
    position: relative;
    appearance: none;
    width: 30px;
    height: 16px;
    border-radius: 200px;
    background-color: #999999;
}

.switcher::after {
    position: absolute;
    top: 1px;
    left: 1px;
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    transition: left 0.15s ease-in-out;
}

.switcher:checked {
    /*заменить на color-rc-green после мержа авторизации*/
    background-color: #66B419;
}

.switcher:checked::after {
    left: 15px;
}
/* Состояние disabled */
.switcher:disabled {
    background-color: var(--color-text-light);
    cursor: default;
}

.switcher:disabled::after {
    background-color: #e6e6e6;
}

.switcher:checked:disabled {
/* поменять оттенок (от color-rc-green)*/
    background-color: #a5d896;
}

.switcher:checked:disabled::after {
    /*
    нужно будет пошаманить с имеющимися оттенками
    серого уже вагон-вагонов и как будто нужно стандартизировать цвета
    */
    background-color: #d9d9d9;
}
</style>
