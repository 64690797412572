<template>
    <div :class="props.className?.wrapper">
        <label
            v-if="props.label"
            :for="id"
            class="py-0 col-form-label-sm"
            :class="[props.className?.label, props.required ? 'label-required' : '']"
        >
            {{ props.label }}
        </label>
        <div :class="props.className?.inputWrapper">
            <div>
                <div
                    class="position-relative"
                    :class="props.className?.inputContainer"
                >
                    <input
                        v-model="inputModel"
                        v-maskito="maskConfig"
                        :disabled="props.disabled"
                        autocomplete="tel"
                        inputmode="tel"
                        class="form-control form-control-sm"
                        :id="id"
                        :class="[props.className?.input, { 'is-invalid': props.error, 'has-icon': props.icon }]"
                        :placeholder="props.placeholder"
                        @blur="emit('trigger:blur')"
                    />
                </div>
                <div
                    v-if="props.error && typeof props.error === 'string'"
                    class="small color-error my-1"
                >
                    {{ props.error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { maskitoTransform } from '@maskito/core';
import { maskitoGetCountryFromNumber, maskitoPhoneOptionsGenerator } from '@maskito/phone';
import { maskitoAddOnFocusPlugin, maskitoRemoveOnBlurPlugin } from '@maskito/kit';
import { getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js/core';
import metadata from 'libphonenumber-js/min/metadata';
import stringHelper from '@/common/helpers/stringHelper';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    className: {
        type: Object,
    },
    value: {
        type: [String, Number],
    },
    error: {
        type: [String, Boolean],
    },
    required: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'text',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
    },
});

const emit = defineEmits(['update:value', 'update:isoCode', 'update:validPhone', 'trigger:blur']);

const id = stringHelper.getRandomUUID();

const inputModel = computed({
    get() {
        return String(props.value === null ? '' : props.value);
    },
    set(value) {
        emit('update:value', value);
        emit('update:validPhone', isValidPhoneNumber(value, metadata));
    },
});

const countryIsoCode = computed(() => maskitoGetCountryFromNumber(inputModel.value, metadata) || 'RU');

const code = computed(() => getCountryCallingCode(countryIsoCode.value, metadata));
const prefix = computed(() => `+${code.value} `);

const phoneOptions = maskitoPhoneOptionsGenerator({
    metadata,
    strict: false,
    countryIsoCode: countryIsoCode.value,
});

const maskConfig = computed(() => ({
    ...phoneOptions,
    plugins: [...phoneOptions.plugins, maskitoAddOnFocusPlugin(prefix.value), maskitoRemoveOnBlurPlugin(prefix.value)],
}));

watch(inputModel, (value) => {
    if (value && !value.startsWith('+')) {
        inputModel.value = maskitoTransform(
            value,
            maskConfig.value,
        )
    }
}, {immediate: true})

watch(countryIsoCode, (code) => {
    emit('update:isoCode', code);
},{immediate: true});
</script>

<style scoped>
.icon {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
}

.after {
    position: absolute;
    top: 6px;
    right: -42px;
}
</style>
